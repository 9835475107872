/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"fotomexiko"}>
        <Column className="--menu pb--08 pt--08" style={{"backgroundColor":"rgba(255,255,255,1)"}} menu={true}>
          
          <Menu className="--right" style={{"maxWidth":1310}} fullscreen={false}>
            
            <ColumnWrapper className="menu-logo-box --center" style={{"maxWidth":310}}>
              
              <Image style={{"maxWidth":142}} alt={""} src={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png"} svg={false} href={"/"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=350x_.png 350w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=660x_.png 660w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png 860w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=1400x_.png 1400w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=2000x_.png 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--style4 --right">
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kurzy"} target={""} content={"Kurzy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/zajezdy"} target={""} content={"Zájezdy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/obchod"} target={""} content={"Obchod"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/sluzby"} target={""} content={"Služby"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/bazen"} target={""} content={"Bazén"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/klub"} target={""} content={"Klub"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/o-nas"} target={""} content={"O nás"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/fotogalerie"} target={""} content={"Fotogalerie"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kontakt"} target={""} content={"Kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column name={"e3d7ygdo27r"} style={{"paddingTop":25,"paddingBottom":30.953125}}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box ff--3" content={"Mexiko"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"gu8pn59qlb9"} style={{"paddingTop":8}} layout={"l29"}>
          
          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/c0ed76eca9fe4abfa0ae056e121aecae_s=350x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/c0ed76eca9fe4abfa0ae056e121aecae_s=350x_.jpg 350w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/d473a72b49e54adeb1faa16e5325174d_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/d473a72b49e54adeb1faa16e5325174d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/d473a72b49e54adeb1faa16e5325174d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/d473a72b49e54adeb1faa16e5325174d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/d473a72b49e54adeb1faa16e5325174d_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/7631daaaf91740358362d17ed2c8e27a_s=350x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/7631daaaf91740358362d17ed2c8e27a_s=350x_.jpg 350w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/39f2a65141204f0ca8461e78435095e9_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/39f2a65141204f0ca8461e78435095e9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/39f2a65141204f0ca8461e78435095e9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/39f2a65141204f0ca8461e78435095e9_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/39f2a65141204f0ca8461e78435095e9_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/39f2a65141204f0ca8461e78435095e9_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center" style={{"marginTop":17.84375}}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/d1eb03157e464be3996fac0457e89721_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/d1eb03157e464be3996fac0457e89721_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/d1eb03157e464be3996fac0457e89721_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/d1eb03157e464be3996fac0457e89721_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/d1eb03157e464be3996fac0457e89721_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/d1eb03157e464be3996fac0457e89721_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/f7b9c42cc7dd408db8cd194f38304d81_s=350x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/f7b9c42cc7dd408db8cd194f38304d81_s=350x_.jpg 350w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/2e87df9fdf134ae9928f5aa9656aefba_s=350x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/2e87df9fdf134ae9928f5aa9656aefba_s=350x_.jpg 350w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/7ed3e1f1448240c4a359a919d32b2eca_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/7ed3e1f1448240c4a359a919d32b2eca_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/7ed3e1f1448240c4a359a919d32b2eca_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/7ed3e1f1448240c4a359a919d32b2eca_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center" style={{"marginTop":17.84375}}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/95503fe5240645818afdf654b79c7a22_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/95503fe5240645818afdf654b79c7a22_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/95503fe5240645818afdf654b79c7a22_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/95503fe5240645818afdf654b79c7a22_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/53608611328e4ab39a4969395ad54e50_s=350x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/53608611328e4ab39a4969395ad54e50_s=350x_.jpg 350w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/905e6cadee5445ba9fda27278642ca75_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/905e6cadee5445ba9fda27278642ca75_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/905e6cadee5445ba9fda27278642ca75_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/905e6cadee5445ba9fda27278642ca75_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/905e6cadee5445ba9fda27278642ca75_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/ee40bb6ff3204de38bf12417660303e6_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/ee40bb6ff3204de38bf12417660303e6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/ee40bb6ff3204de38bf12417660303e6_s=660x_.jpg 660w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center" style={{"marginTop":17.84375}}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/18d72a48eb664e17bef9c4522c705b7b_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/18d72a48eb664e17bef9c4522c705b7b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/18d72a48eb664e17bef9c4522c705b7b_s=660x_.jpg 660w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/3e256e7e1e53402fb9c2f8935eee074d_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/3e256e7e1e53402fb9c2f8935eee074d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/3e256e7e1e53402fb9c2f8935eee074d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/3e256e7e1e53402fb9c2f8935eee074d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/3e256e7e1e53402fb9c2f8935eee074d_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/ac023c7220c749cdb8ecff6eee5a5231_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/ac023c7220c749cdb8ecff6eee5a5231_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/ac023c7220c749cdb8ecff6eee5a5231_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/ac023c7220c749cdb8ecff6eee5a5231_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/7d0ab20c858d47caa721b0c6934b46d6_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/7d0ab20c858d47caa721b0c6934b46d6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/7d0ab20c858d47caa721b0c6934b46d6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/7d0ab20c858d47caa721b0c6934b46d6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/7d0ab20c858d47caa721b0c6934b46d6_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/7d0ab20c858d47caa721b0c6934b46d6_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center" style={{"marginTop":17.84375}}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/2937a51b4da94c3cbf2fb6fb1d1d382b_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/2937a51b4da94c3cbf2fb6fb1d1d382b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/2937a51b4da94c3cbf2fb6fb1d1d382b_s=660x_.jpg 660w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/e58d191208d84c78a724b6639568e9b3_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/e58d191208d84c78a724b6639568e9b3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/e58d191208d84c78a724b6639568e9b3_s=660x_.jpg 660w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/7d8c5c354ecd42d9b25331b175b9317e_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/7d8c5c354ecd42d9b25331b175b9317e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/7d8c5c354ecd42d9b25331b175b9317e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/7d8c5c354ecd42d9b25331b175b9317e_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/5c506ff4986d408a817b4564f49837b8_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/5c506ff4986d408a817b4564f49837b8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/5c506ff4986d408a817b4564f49837b8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/5c506ff4986d408a817b4564f49837b8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/5c506ff4986d408a817b4564f49837b8_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center" style={{"marginTop":17.84375}}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/73f5f37fce054b04b98c7db5bc3822df_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/73f5f37fce054b04b98c7db5bc3822df_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/73f5f37fce054b04b98c7db5bc3822df_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/73f5f37fce054b04b98c7db5bc3822df_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/73f5f37fce054b04b98c7db5bc3822df_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/73f5f37fce054b04b98c7db5bc3822df_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/289cc6a8b4dd44c6a15e93a34b063f72_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/289cc6a8b4dd44c6a15e93a34b063f72_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/289cc6a8b4dd44c6a15e93a34b063f72_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/289cc6a8b4dd44c6a15e93a34b063f72_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/de48580a1b424fe184e28742b9c49792_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/de48580a1b424fe184e28742b9c49792_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/de48580a1b424fe184e28742b9c49792_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/de48580a1b424fe184e28742b9c49792_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/d26d8b2fff64476aa9cd10fe6c2a5300_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/d26d8b2fff64476aa9cd10fe6c2a5300_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/d26d8b2fff64476aa9cd10fe6c2a5300_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/d26d8b2fff64476aa9cd10fe6c2a5300_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1b4itn5" style={{"marginTop":17,"paddingTop":30,"paddingBottom":7.6171875}} layout={"l1"} css={css`
      
    background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 title-box--invert mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">Orca diving, s.r.o.</span>"}>
              </Title>

              <Text className="text-box ff--3 text-box--invert" style={{"marginBottom":0,"paddingBottom":0}} content={"<span style=\"color: rgb(0, 0, 0);\">Ledařská 433/9, Praha 4, 147 00, Česká Republika</span>"}>
              </Text>

              <Image style={{"maxWidth":236,"marginTop":16.6875,"paddingTop":0}} alt={""} src={"https://cdn.swbpg.com/o/13078/000a415e28ce44c5972c4f22d2c183db.PNG"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={""} content={null}>
              </Image>

              <Image style={{"maxWidth":36,"marginTop":0,"paddingTop":10,"paddingBottom":0}} alt={""} src={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png"} svg={false} url={"https://www.facebook.com/orcadivingpotapeni"} href={"https://www.facebook.com/orcadivingpotapeni"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png 350w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}